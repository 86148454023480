import { Link } from 'gatsby'
import { useLocaleContext } from '../LocaleContext'
import { isLinkForUnifiedWeb } from '@dfds-frontend/navigations'

const AutoLink = ({ destination, url, target, children, ...rest }) => {
  const { localeLower } = useLocaleContext()
  if (destination) {
    const destinationUrl = `/${localeLower}/${destination.slug}`
    if (isLinkForUnifiedWeb(destinationUrl)) {
      return (
        <a href={destinationUrl} {...rest}>
          {children}
        </a>
      )
    } else {
      return (
        <Link to={destinationUrl} {...rest}>
          {children}
        </Link>
      )
    }
  }
  if (url) {
    return (
      <a href={url} target={target ? '_self' : '_blank'} {...rest}>
        {children}
      </a>
    )
  }
}

export default AutoLink
